<template>
	<div class="broadcastManage">
		<label class="label" style="display: inline-block;width:75px;text-align: right;">关键字:</label>
		<el-input v-model="searchKey" placeholder="直播间ID/直播标题" style="width: 250px;margin-right: 10px;" clearable></el-input>
		<div class="filter-container" style="margin-top: 15px;">
			<div class="filter-item">
				<label class="label">开播时间:</label>
				<el-date-picker v-model="searchTime" type="datetimerange" :picker-options="pickerOptions" range-separator="～"
					start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</div>
			<div class="filter-item">
				<label class="label">直播状态:</label>
				<el-select v-model="searchState" style="width: 150px;margin-right: 10px;" @change="keyChange" clearable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in searchStateList" :key="item.id" :label="item.value" :value="item.id"></el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<el-button type="primary" @click="goodsFilter">查询</el-button>
				<buttonPermissions :datas="'broadcastManageGet'">
					<el-button style="margin-left: 15px;" @click="getNewBroad">获取最新直播</el-button>
				</buttonPermissions>
			</div>
		</div>

		<el-table  class="table-back-write" :data="broadData" style="width: 100%;" v-loading="loading" ref="multipleTable" :row-key="rowKeys">
			<el-table-column prop="roomid" label="直播间ID"></el-table-column>
			<el-table-column prop="anchorName" label="主播" width="120"></el-table-column>
			<el-table-column prop="anchorName" label="主播信息" width="160">
				<template slot-scope="scope">
					<div>{{ scope.row.bindUserName }}({{ scope.row.bindUserLevel | getDistributRoleName }})</div>
					<div>ID：{{ scope.row.userId }}</div>
					<div>{{ scope.row.bindUserPhone }}</div>
				</template>
			</el-table-column>
			<el-table-column prop="bindUserTime" label="关联时间" width="180"></el-table-column>
			<el-table-column label="直播标题" width="300">
				<template slot-scope="scope">
					<div class="product-info" style="align-items:flex-start">
						<img v-if="scope.row.shareImg" :src="scope.row.shareImg" style="width:100px;height:75px" alt="" />
						<svg-icon v-else icon-class="noImgIcon" />
						<div style="min-height: auto;width:200px;">
							<pre style="white-space: pre-wrap;margin:0;">{{scope.row.name }}</pre>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="AddTime" label="直播时间" width="370">
				<template slot-scope="scope">
					<el-date-picker style="width:335px;"
									@input="changedata(scope.row)"
									:clearable="false"
									size="small"
									v-model="scope.row.StartEndtime"
									value-format="yyyy-MM-dd HH:mm:ss"
									format="yyyy-MM-dd HH:mm:ss"
									type="datetimerange"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									disabled
					></el-date-picker>
				</template>
			</el-table-column>
			<el-table-column prop="StateValue" label="直播状态" width="200">
				<template slot-scope="scope">
					<span v-if="scope.row.liveStatus == '101'">直播中</span>
					<span v-else-if="scope.row.liveStatus == '102'">未开始</span>
					<span v-else-if="scope.row.liveStatus == '103'">已结束</span>
					<span v-else-if="scope.row.liveStatus == '104'">禁播</span>
					<span v-else-if="scope.row.liveStatus == '105'">暂停</span>
					<span v-else-if="scope.row.liveStatus == '106'">异常</span>
					<span v-else>已过期</span>
				</template>
			</el-table-column>
			<el-table-column prop="StateValue" label="直播类型" width="200">
				<template slot-scope="scope">
					<span v-if="scope.row.isAuthor">官方直播</span>
					<span v-else-if="!scope.row.isAuthor">主播直播</span>
					<el-button type="text" class="pointers" @click="updateAuthor(scope.row)" style="margin-left:10px;">{{scope.row.isAuthor? '设为主播' : '设为官方'}}</el-button>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="200">
				<template slot-scope="scope">
					<el-button type="text" class="pointers" @click="gettransData(scope.row)">交易转化</el-button>
					<el-button type="text" class="pointers" v-if="scope.row.liveStatus=='103'" @click="showReplay(scope.row)">直播回放</el-button>
					<el-button v-if="scope.row.liveStatus == '102'" type="text" class="pointers" @click="showBindUserFunc(scope)">
						{{ !scope.row.bindUserTime ? '关联主播' : '更换主播' }}
					</el-button>
					<buttonPermissions :datas="'broadcastManageDel'">
							<span style="color:#f00;margin-left:15px;cursor: pointer;" v-if="scope.row.State!=1" @click="deleteChose(scope.row)">删除</span>
					</buttonPermissions>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination style="margin:20px 0; text-align: right" @size-change="handleSizeChange"
					   @current-change="handleCurrentChange" :current-page="pageNo" :page-sizes="[10, 20, 30, 40, 50]"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>

		<!-- 修改序号弹框 -->
		<el-dialog title="首页序号" :visible.sync="headOrderVisible" width="500px" @close="CanClose">
			<el-form
				:model="ruleForm"
				ref="ruleForm"
				label-width="100px"
				class="demo-ruleForm"
				:rules="rules"
			>
				<el-form-item label="首页序号" prop="headSort">
					<input
						v-model="ruleForm.headSort"
						style="padding:0 10px;width:250px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"
						type="number"
					/>
					<div style="color:#888;font-size: 14px;">数值越大，直播间展示越靠前</div>
				</el-form-item>

				<div style="text-align: center;margin-top: 50px;">
					<el-button
						type="primary"
						@click="sureSends('ruleForm')"
						style="width:120px"
						:loading="saveLoading"
					>保存</el-button>
				</div>
			</el-form>
		</el-dialog>

		<!-- 修改序号弹框 -->
		<el-dialog title="交易转化数据" :visible.sync="transferVisible" width="800px">
			<el-row :gutter="20">
				<el-col :span="6">
					<p>下单人数</p>
					<div class="num">{{transferData.orderPeoples}}</div>
				</el-col>
				<el-col :span="6">
					<p>下单金额</p>
					<div class="num">{{transferData.orderAmount}}</div>
				</el-col>
				<el-col :span="6">
					<p>非特供订单金额</p>
					<div class="num">{{transferData.nonSpecialOrderAmount}}</div>
				</el-col>
				<el-col :span="6">
					<p>特供订单金额</p>
					<div class="num">{{transferData.specialOrderAmount}}</div>
				</el-col>
			</el-row>
			<el-row :gutter="20" style="padding: 30px 0">
				<el-col :span="6">
					<p>支付人数</p>
					<div class="num">{{transferData.payedPeoples}}</div>
				</el-col>
				<el-col :span="6">
					<p>支付金额</p>
					<div class="num">{{transferData.payedAmount}}</div>
				</el-col>
				<el-col :span="6">
					<p>非特供订单金额（支付）</p>
					<div class="num">{{transferData.payedNonSpecialAmount}}</div>
				</el-col>
				<el-col :span="6">
					<p>特供订单金额（支付）</p>
					<div class="num">{{transferData.payedSpecialAmount}}</div>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="6">
					<p>Commission</p>
					<div class="num">{{transferData.specialSaleProfit}}</div>
				</el-col>
				<el-col :span="6">
					<p>Prize</p>
					<div class="num">{{transferData.shareSaleProfit}}</div>
				</el-col>
			</el-row>
		</el-dialog>

		<el-dialog title="直播回放" :visible.sync="replayVisible" width="700px">
			<div style="margin-bottom: 15px;">直播结束后大约 10 分钟会生成回放，源视频无评论等内容</div>
			<div v-for="(item , index) in replayList" :key="index">
				<br v-if="item.mediaUrl.indexOf('m3u8') < 0" />
				<a v-if="item.mediaUrl.indexOf('m3u8') < 0"
					:href="item.mediaUrl"
					target="_blank"
					style="font-size: 12px;color: #409EFF;margin-top: 10px;"
				>{{item.mediaUrl}}</a>
			</div>
		</el-dialog>

		<!-- 关联修改主播 -->
		<el-dialog :title="bindUserTitle" :visible.sync="bindUserFlag" width="660px">
			<el-form v-if="bindUserData" :model="bindUserData" ref="editUpperForm" size="small" label-width="100px">
				<el-form-item label="主播ID：">
					<div style="display: flex;">
						<el-input style="width: 160px" v-model="bindUserData.userId" placeholder="输入主播ID查询用户"></el-input>
						<el-button :loading="searchUpperLoading" type="primary" style="margin-left:20px;" @click="searchBindUser">查询</el-button>
					</div>
				</el-form-item>
				<el-row :gutter="20" style="margin: 30px auto;">
					<el-col v-if="bindUserData.oldData" :span="8" :offset="2">
						<el-card class="box-card" shadow="never" :body-style="{ padding: '5px 15px'}">
							<p>ID：{{ bindUserData.oldData.id }}</p>
							<p>姓名：{{ bindUserData.oldData.name }}</p>
							<p>手机：{{ bindUserData.oldData.phone }}</p>
							<p>等级：{{ bindUserData.oldData.level | getDistributRoleName }}</p>
						</el-card>
					</el-col>
					<el-col v-if="bindUserData.oldData" :span="4" style="text-align: center;">
						<i class="el-icon-right" style="font-size: 36px; margin: 40px auto 0;"></i>
					</el-col>
					<el-col :span="8">
						<el-card class="box-card" shadow="never" :body-style="{ padding: '5px 15px'}">
							<div v-if="bindUserData.newData">
								<p>ID：{{ bindUserData.newData.id }}</p>
								<p>姓名：{{ bindUserData.newData.name }}</p>
								<p>手机：{{ bindUserData.newData.phone }}</p>
								<p>等级：{{ bindUserData.newData.level | getDistributRoleName }}</p>
							</div>
							<span v-else style="padding: 59px 0; display: block; text-align: center">未获取</span>
						</el-card>
					</el-col>
				</el-row>
				<div style="text-align:center;">
					<el-button size="small" :disabled="!bindUserData.newData" type="primary" @click="saveEditbindUser">
						{{ bindUserData.oldData ? '确认更换' : '关联主播' }}
					</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import config from '@/config/index'
import {
	liveRoomList,
	liveRoomRefreshCache,
	liveRoomisShow,
	liveRoomDelete,
	liveRoomUpdate,
	liveRoomupdate,
    LiveroomTradedata,
    getLiveReplay,
    updateAuthor,
	getAgentInfo,
	updateBindUser,
} from '@/api/goods'
import buttonPermissions from '@/components/buttonPermissions';

export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			transferData: {},
			transferVisible: false,
			replayVisible: false,
			replayList: [],

			searchKey: '',
			searchTime: '',  //搜索时间
			pickerOptions: {        //时间区间提示
				shortcuts: [{
					text: '最近一周',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			searchState: null,
			searchStateList: [
				{
					id: 102,
					value: '预告'
				},
				{
					id: 101,
					value: '直播中'
				},
				{
					id: 103,
					value: '已结束'
				}],

			broadData: [],
			loading: false,
			pageNo: 0,
			pageSize: 20,
			total: 0,
			headOrderVisible: false,
			ruleForm: {
				headSort: ''
			},
			rules: {
				headSort: {
					required: true,
					message: '请输入首页序号',
					trigger: 'blur'
				}
			},
			saveLoading: false,
			imgUrl: config.IMG_BASE,
			sortData: {},

			bindUserTitle: '修改上级/横推',
			bindUserFlag: false,
			bindUserData: null,
			searchUpperLoading: false,
		}
	},
	created () {
		this.getList();
	},
	methods: {
		async changedata (row) {
			try {
				let data = {
					roomid: row.roomid,
					StartTime: row.StartEndtime[0],
					EndTime: row.StartEndtime[1]
				}
				let starts = new Date(row.StartEndtime[0]).getTime()
				let ends = new Date(row.StartEndtime[1]).getTime()
				if (starts > ends || starts == ends) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '开始时间需小于结束时间!'
					});
					return
				}
				let result = await liveRoomupdate(data)
				if (result.IsSuccess) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功!'
					});
					this.getList();
				}

			} catch (e) {
				//TODO handle the exception
				console.log(e)
			}
			console.log(row, '时间选择器')

		},
		// 修改直播状态
		async stateFun (record, type) {
			try {
				let data = {
					roomid: record.roomid,
					State: type
				}
				let result = await liveRoomUpdate(data)
				if (result.IsSuccess) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功!'
					});
				}

			} catch (e) {
				//TODO handle the exception
			} finally {
				this.getList();
			}

		},
		//交易转化
		async gettransData (record) {
			this.transferVisible = true
			try {
				let result = await LiveroomTradedata({ roomId: record.roomid })
				this.transferData = result.data;
			} catch (err) {
				console.log(err);
			}
		},
		//关联更换主播
		showBindUserFunc({ row }) {
			this.bindUserTitle = row.bindUserPhone ? '更换主播' : '关联主播';
			this.bindUserFlag = true;
			this.bindUserData = {
				userId: '',
				roomId: row.roomid,
				oldData: row.bindUserPhone ? {
					id: row.userId,
					name: row.bindUserName,
					level: row.bindUserLevel,
					phone: row.bindUserPhone,
				} : null,
			};
		},
		//查询用户信息
		async searchBindUser() {
			try {
				let userId = this.bindUserData.userId;
				if (this.bindUserData.oldData && userId == this.bindUserData.oldData.id) {
					this.$message({type: 'error', message: '不能选择相同用户！'});
					return;
				}
				this.searchUpperLoading = true;
				let { data, alertMsg } = await getAgentInfo({ memberId: userId });
				if (data) {
					this.bindUserData.newUserId = data.memberId;
					this.bindUserData.newData = {
						id: data.memberId,
						name: data.userName || '未知',
						phone: data.userPhone,
						level: data.agentLevel,
					};
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e);
			}
			this.searchUpperLoading = false;
		},
		//确认修改/关联主播
		saveEditbindUser() {
			updateBindUser({
				roomId: this.bindUserData.roomId,
				userId: this.bindUserData.newUserId,
			}).then(res => {
				if(res.success) {
					this.$message({showClose: true, type: 'success', message: '操作成功!'});
					this.getList();
					this.bindUserFlag = false;
				} else {
					this.$message({showClose: true, type: 'error', message: res.alertMsg || '未知错误!'});
				}
			})
		},
		// 删除直播间
		deleteChose (record) {
			this.$confirm('删除成功后，不可恢复，是否确认确认继续删除?', '提示', {
				confirmButtonText: '继续删除',
				cancelButtonText: '关闭',
				type: 'warning'
			}).then(async () => {
				try {
					let result = await liveRoomDelete({
						id: record.id
					})
					if (result.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功!'
						});
					}
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				}
			}).catch(() => {
				this.$message({
					showClose: true,
					type: 'info',
					message: '已取消删除'
				});
			}).finally(() => {
				this.pageNo = 1;
				setTimeout(() => {
					this.getList();
				}, 500)

			})
		},
		// 获取列表
		async getList () {
			try {
				this.loading = true

				let data = {
					keyWord: this.searchKey, // 搜索关键字
					bigTime: this.starTime,
					endTime: this.endTime,
					liveStatus: this.searchState,
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				}
				if (this.searchTime) {
					data.bigTime = this.searchTime[0];
					data.endTime = this.searchTime[1];
				}
				let result = await liveRoomList(data);
				this.total = result.data.total;
				this.broadData = result.data.records || [];
				this.broadData.map(item => {
					item.StartEndtime = [item.startTime, item.endTime]
					return item
				})


			} catch (e) {
				//TODO handle the exception
				console.log(e)
			} finally {
				this.loading = false
			}

		},
		keyChange () {
			// this.pageNo = 1
			// this.getList()
		},
		goodsFilter () {
			this.pageNo = 1
			this.getList()
		},
		// 获取最新直播
		async getNewBroad () {
			try {
				let result = await liveRoomRefreshCache({ limit: 100, start: 0 })
				if (result.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功!'
					});
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e)
			} finally {
				this.getList()
			}

		},
		rowKeys (row) {
			return row.Id
		},
		// 是否展示在首页
		async isShowHead (record) {
			try {
				let data = {
					roomid: record.roomid,
					IsShow: !record.IsShow,
					SortDesc: record.SortDesc
				}
				let result = await liveRoomisShow(data)
				if (result.IsSuccess) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功!'
					});
				}

			} catch (e) {
				//TODO handle the exception
			} finally {
				this.getList()
			}

		},
		// 修改序号
		sortsEdit (record) {
			this.sortData = record;
			this.ruleForm.headSort = record.SortDesc
			this.headOrderVisible = true
		},
		// 切换显示条数
		handleSizeChange (val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getList();
		},

		// 翻页
		handleCurrentChange (val) {
			// console.log(`当前页: ${val}`);
			this.pageNo = val;
			this.getList();
		},
		CanClose () {
			this.ruleForm.headSort = ''
		},
		codeChangefun () {
			this.ruleForm.headSort = this.ruleForm.headSort.replace(/[\W]/g, '');
		},
		// 保存修改序号
		sureSends (formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					try {
						this.saveLoading = true

						let data = {
							roomid: this.sortData.roomid,
							IsShow: this.sortData.IsShow,
							SortDesc: this.ruleForm.headSort
						}
						let result = await liveRoomisShow(data)
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '操作成功!'
							});
							this.getList();
							this.headOrderVisible = false
						}

					} catch (e) {
						//TODO handle the exception
					} finally {
						this.saveLoading = false
					}

				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},

		//回放相关
		showReplay (record) {
			this.replayVisible = true;
			this.getReplayData(record);
        },
        //互获取回放
		async getReplayData (record) {
			try {
				// const res = await zbRooms({
				// 	start: 0,
				// 	limit: 100,
				// 	action: "get_replay", // 获取回放
				// 	room_id: record.roomid, // 直播间id
				// })
                // this.replayList = res.Result.live_replay.reverse();

                let result = await getLiveReplay({ limit: 100, start: 0, roomId:record.roomid});
                this.replayList = result.data.liveReplay;
                console.log(this.replayList);
			} catch (e) {
				console.log(e);
			}

        },
        //更改主播身份
        async updateAuthor(row){
            let params = {
                isAuthor: row.isAuthor ? 0 : 1,
                roomId: row.roomid
            }
            let result = await updateAuthor(params);
            if (result.success) {
                row.isAuthor = row.isAuthor ? 0 : 1;
                this.$message({
                    showClose: true,
                    type: 'success',
                    message: '操作成功!'
                });
            }else{
                this.$message({
                    showClose: true,
                    type: 'error',
                    message: result.alertMsg
                });
            }
        }

	}
}
</script>

<style lang="less">
.broadcastManage {
	width: 100%;
	background: #fff;
	padding: 15px;
	.el-card__body {
		padding-top: 0px !important;
	}
	.pointers {
		cursor: pointer;
	}

	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	.number__input {
		border: 1px solid #ccc;
	}
	.transfercontent {
		// justify-content: ;
		flex-wrap: wrap;
		.item_content {
			width: 25%;
			margin-top: 45px;
			.num {
				margin-top: 10px;
			}
		}
	}
}
</style>
